import { Action, Article, LeazardForm } from "@nettbureau/gatsby-theme-katalog";
import { graphql } from "gatsby";
import { get } from "lodash";
import React from "react";
import { RelatedProducts } from "../components";
import no from "../messages";
import { CompanyTemplate } from "./Company";

interface Props {
  data: {
    company: any;
  };
}

export default ({ data }: Props) => {
  const { company } = data;
  return (
    <Article
      sidebar={
        <LeazardForm id={get(company, ["cms", "settings", "leazardFormId"])} />
      }
    >
      <CompanyTemplate
        title={no.string("TEMPLATE_missing_product_header", company)}
      >
        {no.markdown("TEMPLATE_missing_product", company)}
        <Action
          link={{ label: "Sammenlign strømpriser!", url: "/strømpriser" }}
        />
        <br />
        <br />
        <RelatedProducts
          title={no.string("TEMPLATE_products_title", company)}
          description={no.string("TEMPLATE_products_description", company)}
          products={company.products}
        />
      </CompanyTemplate>
    </Article>
  );
};

export const query = graphql`
  query($id: String!) {
    company: stromCompany(id: { eq: $id }) {
      companyName
      organizationNumber
      url
      customerSupportEmail
      customerSupportUrl
      customerPhone
      totalProducts
      products {
        productName
        slug
      }
      cms {
        settings {
          leazardFormId
        }
      }
    }
  }
`;
